import React from 'react';

const AppFooter = () => {

    return (
        <div>
        </div>
    );
}

export default AppFooter;
